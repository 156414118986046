#app {
    /* background-color: var(--bs-body-bg); */
    /* background-color: #103e6a !important; */
    overflow: auto;
}
.app {
  min-height: 100vh;
}
.bgwcdark {
  background-color: #122d46 !important;
}
.bgwclight {
  /* background-color: #103e6a !important; */
  background-color:#e3e3e3 !important
}
.bgtabledark {
  background-color: #122d46 !important;
  color: #e9e9e9 !important;
}
.bgtablelight {
  background-color: #103e6a !important;
  color: #e9e9e9 !important;
}
.sidebar {
  width: 250px;
  background-color: #f5f5f5;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.status-control {
  float: right;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.status-control form {
  display: flex;
  gap: 10px;
  align-items: center;
}

.status-control select,
.status-control input,
.status-control button {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.status-control button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.status-control button:hover {
  background-color: #0056b3;
}

.employee-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.employee-table th,
.employee-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.employee-table th {
  background-color: #f8f9fa;
}

.department-header {
  background-color: #e9ecef;
  font-weight: bold;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 10px 0;
}

.sidebar a {
  text-decoration: none;
  color: #333;
}

.sidebar a:hover {
  color: #007bff;
}

.employee-table tr:hover:not(.department-header) {
  background-color: #f8f9fa;
}

td[data-status="In"] {
  color: green;
}

td[data-status="Out"] {
  color: red;
}

td[data-status="Remote"] {
  color: blue;
}

.employee-table input,
.employee-table select {
  width: 90%;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.employee-table button {
  margin: 0 4px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.employee-table button:hover {
  background-color: #f0f0f0;
}

/* Custom styles that extend Bootstrap */
.sidebar .nav-link:hover {
  background-color: rgba(0,0,0,0.05);
}

.table td {
  vertical-align: middle;
} 

.bg-wc-darkblue {
  background-color: #003e6b !important;
  color:#e9e9e9 !important;
}

.bg-wc-lightblue {
  background-color: #00a3d8 !important;
  color:#e9e9e9 !important;
}

.nameCol {
  width: 225px !important;
}

.extCol {
  width: 100px !important;
}

.commentCol {
  width: auto !important;
}

.statusCol {
  width: 85px !important;
}

.actionsCol {
  width: 100px !important;
  text-align: right;
}

.Toastify__toast-container {
  width:auto !important;
}
:root {
  --toastify-color-dark: #3b5160 !important;
}

.btn-label {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0.6rem;
  background: rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.btn {
  padding-left: 3rem;
  text-align: left;
}
.update-Text {
  margin-left: 30px;
}
.nav-pills .nav-link.active {
  background-color: #003e6b !important;
}

.custom-tooltip {
  min-width: 150px; /* Adjust this value as needed */
  white-space: nowrap !important;
}
.tooltip-inner {
  max-width: 1000px !important;
}

#manageDepartmentsTable>tbody>tr>td {
  padding:0px !important;
}

#manageLinksTable>tbody>tr>td {
  padding:0px !important;
}

.btn:hover {
  /* background-color: #35b8e0 !important; */
  transform:scale(1.1);
}
.btn:active {
  transform:scale(1.0);
}

.navbar-brand img {
  height:auto;
  max-height: 100px;
}

.navbar>.container {
  box-shadow: 0px 5px 5px #0000003b !important;
}

#status-dropdown::after {
  display: none;
}

.status-dropdown-toggle {
  background: none;
  border: none;
  padding: 0;
}

.status-dropdown-toggle:hover,
.status-dropdown-toggle:focus {
  background: none;
  border: none;
}

.status-badge {
    opacity: 0.9;
    transform: scale(1);
}

.status-badge:hover {
    opacity: 1;
    transform: scale(1.1);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.animate {
  animation-duration: 0.1s;
  -webkit-animation-duration: 0.1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(-2rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(-2rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
.navbar-bg-light {
  background-image: linear-gradient(90deg, rgb(11 45 77) 0%, rgba(16, 62, 106, 1) 62%) !important;
}
.navbar-bg-dark {
}

@media only screen and (max-width: 990px) {
  .navbar-brand>img {
    max-height: 80px;
  }
}

.hover-visible {
  opacity: 0;
}

tr:hover .hover-visible {
  opacity: 1;
}

.employee-row:hover .pin-button {
  visibility: visible !important;
}

.pin-button {
  transition: visibility 0.2s ease;
}

.pinnedComment {
  font-size: 0.8rem;
}

.bi-x:hover {
  color: red !important;
}
