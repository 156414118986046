//
// britechart.scss
//

.britechart,
.tick text {
    font-family: $font-family-base;
    font-size: 0.75rem;
}

.horizontal-grid-line,
.vertical-grid-line,
.extended-x-line,
.extended-y-line {
    stroke: var(--#{$prefix}border-color);
}

.tick text,
.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value {
    fill: var(--#{$prefix}secondary-color);
}