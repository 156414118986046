//
// react select
//

.react-select {
    > div {
      border: $input-border-width solid $input-border-color;
      background-color: $input-bg !important;
  
      &:hover {
        border: $input-border-width solid $input-border-color;
      }
    }
  }
  
  .react-select__control--is-focused {
    border-color: $input-focus-border-color !important;
    box-shadow: none !important;
  }
  
  .react-select__indicator-separator {
    display: none !important;
  }
  
  .react-select__dropdown-indicator {
    color: #{map-get($grays, "600")} !important;
  }
  
  .react-select__menu {
    background-color: $dropdown-bg !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: $dropdown-border-radius !important;
    z-index: $zindex-dropdown !important;
  }
  
  .react-select__menu-list {
    border: $dropdown-border-width solid $dropdown-border-color !important;
    border-radius: $dropdown-border-radius !important;
  }
  
  .react-select__option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
    color: $dropdown-link-color !important;
  
    &:hover {
      color: $dropdown-link-hover-color !important;
      background-color: $dropdown-link-hover-bg !important;
    }
  }
  
  .react-select__option--is-focused,
  .react-select__option--is-selected {
    color: $dropdown-link-hover-color !important;
    background-color: $dropdown-link-hover-bg !important;
  }
  .react-select__single-value {
    color: #{map-get($grays, "600")} !important;
  }
  .react-select__multi-value {
    background-color: rgba($primary, 0.2) !important;
    color: $primary !important;
  
    .react-select__multi-value__label {
      color: $primary;
    }
  
    .react-select__multi-value__remove {
      &:hover {
        background-color: rgba($primary, 0.2) !important;
        color: $primary !important;
      }
    }
  }
  
  // react-bootstrap-typeahead style
  .rbt-token {
    background-color: rgba($primary, 0.2) !important;
    color: $primary !important;
    .rbt-token-remove-button {
      background-color: transparent;
      border: none;
      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
      }
    }
  }
  
  .rbt-input-multi.focus {
    box-shadow: none !important;
    background-color: $input-focus-bg !important;
    border-color: $input-focus-border-color !important;
  }
  
  // Topbar search
  .app-search {
    .react-select__menu-list {
      .react-select__option {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        color: #{map-get($grays, "700")};
        background-color: transparent;
        .dropdown-item {
          &.active,
          &:active {
            background-color: transparent;
          }
        }
        &:hover {
          color: $dark;
          background-color: $dropdown-link-hover-bg;
        }
        &--is-selected {
          background-color: $dropdown-link-hover-bg;
          .dropdown-item {
            color: $dark !important;
          }
        }
        &--is-disabled {
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  
    .react-select__value-container {
      &--has-value {
        .react-select__single-value {
          i,
          img,
          .user-subinfo {
            display: none !important;
          }
          .dropdown-item {
            color: #{map-get($grays, "600")} !important;
            .drop-username {
              color: #{map-get($grays, "600")} !important;
            }
            &:hover {
              color: $dropdown-link-color;
              background-color: transparent;
            }
          }
  
          .dropdown-item,
          .dropdown-item .drop-username {
            width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

//
// Select 2
//

.select2-container {
    width: 100% !important;

    .select2-selection--single {
        border: $input-border-width solid $input-border-color;
        height: $input-height;
        background-color: $input-bg;
        outline: none;

        .select2-selection__rendered {
            line-height: 36px;
            padding-left: 12px;
            color: $input-color;
        }

        .select2-selection__arrow {
            height: 34px;
            width: 34px;
            /*rtl:ignore*/
            right: 3px;

            b {
                border-color: var(--#{$prefix}gray-500) transparent transparent transparent;
                border-width: 5px 5px 0 5px;
            }
        }
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent var(--#{$prefix}gray-500) transparent !important;
                border-width: 0 5px 5px 5px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 6px 12px;


}

.select2-dropdown {
    border: $dropdown-border-width solid $dropdown-border-color;
    box-shadow: var(--#{$prefix}box-shadow);
    background-color: $dropdown-bg;
    z-index: $zindex-modal + 1;
}

.select2-results__options {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
        margin-right: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--#{$prefix}dark-rgb), 0.2);
        border-radius: 10px;
        margin-right: 5px;
        border: none;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.select2-container--default {
    .select2-results__option--selected {
        background-color: var(--#{$prefix}tertiary-bg);
    }

    .select2-search--dropdown {
        padding: 10px;
        background-color: $dropdown-bg;

        .select2-search__field {
            outline: none;
            border: 1px solid $input-border-color;
            background-color: $input-bg;
            color: $input-color;
            border-radius: $input-border-radius;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: var(--#{$prefix}primary);
    }

    .select2-results__option[aria-selected="true"] {
        background-color: $dropdown-bg;
        color: $dropdown-link-active-color;

        &:hover {
            background-color: var(--#{$prefix}primary);
            color: $white;
        }
    }

    .select2-selection--multiple {
        .select2-selection__choice__display {
            padding-left: 36px;
            padding-right: 5px;
        }
    }

    .select2-selection--single {
        .select2-selection__arrow {
            /*rtl:ignore*/
            right: 1px;
            /*rtl:ignore*/
            left: auto;
        }
    }
}

.select2-container {
    .select2-selection--multiple {
        min-height: $input-height;
        border: $input-border-width solid $input-border-color !important;
        background-color: $input-bg;

        .select2-selection__rendered {
            padding: 1px 4px;
        }

        .select2-search__field {
            border: 0;
            color: $input-color;
        }

        .select2-selection__choice {
            background-color: var(--#{$prefix}primary);
            border: none;
            color: $white;
            border-radius: 3px;
            padding: 0px 7px 0 0;
            margin-top: 6px;
        }

        .select2-selection__choice__remove {
            color: $white;
            margin-right: 7px;
            border-color: tint-color($primary, 10%);
            padding: 0 8px;

            // position: relative;
            &:hover {
                color: $white;
                background-color: var(--#{$prefix}primary);
            }
        }
    }

    .select2-search--inline {
        .select2-search__field {
            margin-top: 7px;
            font-family: $font-family-base;
        }
    }

    .select2-search {
        textarea {
            &::placeholder {
                color: $input-placeholder-color;
            }
        }
    }
}

[dir="rtl"] .select2-container--open .select2-dropdown {
    left: auto;
    right: 0;
}